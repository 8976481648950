import React from "react";

import { CartProvider } from "../context/cartContext";
import { WarehouseProvider } from "../context/warehouseContext";

import Header from "../components/header";
import Footer from "../components/footer";

export default function Layout({ children }) {
  return (
    <WarehouseProvider>
      <CartProvider>
        <Header />
        <main>{children}</main>
        <Footer />
      </CartProvider>
    </WarehouseProvider>
  );
}
