import React, { createContext, useContext, useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";

const WarehouseContext = createContext();

function populateWarehouse({ data, setWarehouse }) {
  const goods = data.getGoods.nodes;
  const tints = data.getTints.nodes;
  const volumes = data.getVolumes.nodes;
  const tempWarehouse = [];

  goods.forEach((good) => {
    const item = {
      id: good.id,
      name: good.frontmatter.name,
      image: good.frontmatter.image,
      tint: tints.find(
        (tint) => good.frontmatter.tint === tint.frontmatter.name
      ),
      volume: volumes.find(
        (volume) => good.frontmatter.volume === volume.frontmatter.name
      ),
    };
    tempWarehouse.push(item);
  });
  setWarehouse(tempWarehouse);
}

function filterWarehouse({ warehouse, setFilteredWarehouse, filter }) {
  const tempFilteredWarehouse = [];
  warehouse.forEach((item) => {
    switch (filter) {
      case "all":
        tempFilteredWarehouse.push(item);
        break;
      case "0_4":
        if (item.volume.frontmatter.volume === "0,4") {
          tempFilteredWarehouse.push(item);
        }
        break;
      case "5":
        if (item.volume.frontmatter.volume === "5") {
          tempFilteredWarehouse.push(item);
        }
        break;
      default:
        break;
    }
  });
  setFilteredWarehouse(tempFilteredWarehouse);
}

function useWarehouseData() {
  const data = useStaticQuery(graphql`
    {
      getGoods: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(content/goods)/" } }
        sort: { fields: frontmatter___name, order: ASC }
      ) {
        nodes {
          id
          frontmatter {
            name
            image
            tint
            volume
          }
        }
      }
      getTints: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(content/tints)/" } }
      ) {
        nodes {
          id
          frontmatter {
            name
            image
            properties
          }
        }
      }
      getVolumes: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(content/volumes)/" } }
      ) {
        nodes {
          id
          frontmatter {
            name
            volume
            price
          }
        }
      }
    }
  `);
  return data;
}

function WarehouseProvider({ children }) {
  const [warehouse, setWarehouse] = useState([]);
  const [filteredWarehouse, setFilteredWarehouse] = useState([]);
  const [filter, setFilter] = useState();
  const data = useWarehouseData();

  //Populates Warehouse
  useEffect(() => {
    populateWarehouse({ data, setWarehouse });
  }, [data]);

  //Filter Warehouse
  useEffect(() => {
    filterWarehouse({ warehouse, setFilteredWarehouse, filter });
  }, [warehouse, filter]);

  const getItemByID = ({ id }) => {
    const item = warehouse.find((element) => element.id === id);
    return item;
  };

  const values = { warehouse, filteredWarehouse, getItemByID, setFilter };

  return (
    <WarehouseContext.Provider value={values}>
      {children}
    </WarehouseContext.Provider>
  );
}

function useWarehouse() {
  const value = useContext(WarehouseContext);
  return value;
}

export { WarehouseContext, WarehouseProvider, useWarehouse };
