import React from "react";
import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";

export default function Footer() {
  const thisYear = new Date().getFullYear();

  return (
    <div className="bg-amber-900">
      <div className="container pb-4 mx-auto">
        <div className="flex flex-wrap md:flex-nowrap w-full h-full px-10 pt-8 align-top justify-center ">
          <div className="w-32 shrink-0 order-1 md:order-1 mr-2">
            <img
              src="/vnd/vnd_logo.png"
              alt="Vosky na Dřevo Logo"
              className="flex-none h-24"
            />
          </div>

          <div className="flex-grow px-4 order-3 md:order-2 mt-2 md:mt-0">
            <div className="flex justify-center mt-6 mb-10 text-white align-middle space-x-2 text-5xl">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/voskynadrevo.cz"
              >
                <FaFacebook />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.youtube.com/channel/UC4pSdgVDyjry-ZlhcfJa7jQ"
              >
                <FaYoutube />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/voskynadrevo_cz/"
              >
                <FaInstagram />
              </a>
            </div>
          </div>
          <div className="w-32 shrink-0 order-2 md:order-3 pl-2">
            <a target="_blank" rel="noreferrer" href="http://fiddes.co.uk">
              <img
                src="/vnd/fiddes_logo.svg"
                alt="Fiddes Logo"
                className="flex-none h-24"
                color="white"
              />
            </a>
          </div>
        </div>
        <div className="text-center text-sm font-light text-white">
          Copyright @ {thisYear} by{" "}
          <a href="https://www.jiribeck.com" target="_blank" rel="noreferrer">
            jiribeck.com
          </a>
        </div>
      </div>
    </div>
  );
}
