import React, { useState } from "react";
import { Link } from "gatsby";

import { FaTimes, FaBars } from "react-icons/fa";

import MenuLink from "./menuLink";

export default function Header() {
  const [hideMenu, setHideMenu] = useState(true);

  return (
    <div className="sticky top-0 z-50 bg-amber-900 shadow-2xl">
      <div className="container px-2 md:px-6 mx-auto">
        <div className="flex h-16 md:h-24 align-middle item-center">
          <Link to="/">
            <img
              src="/vnd/vnd_logo.png"
              alt="Vosku na Dřevo Logo"
              className="flex-none h-14 md:h-20 text-white"
            />
          </Link>
          <div className="flex-grow" />
          <nav className="hidden md:flex items-center flex-none space-x-4 text-white">
            <MenuLink href="/#testimonials" label="řekli o nás" />
            <MenuLink href="/#tints" label="odstíny" />
            <MenuLink href="/#contactForm" label="kontakt" />
            <MenuLink href="/shop-page" label="obchod" />
          </nav>
          <div className="flex md:hidden pr-2">
            <button
              onClick={() => setHideMenu(!hideMenu)}
              className="text-white text-xl"
            >
              {hideMenu ? <FaBars /> : <FaTimes />}
            </button>
          </div>
        </div>
      </div>
      {/* FIXME: When opens on top of the page, it will move content down */}
      {!hideMenu && (
        <div
          onClick={() => setHideMenu(true)}
          onKeyDown={() => setHideMenu(true)}
          aria-hidden="true"
        >
          <nav className="flex flex-col items-center text-white space-y-4 py-2 text-xl">
            <MenuLink href="/#testimonials" label="řekli o nás" />
            <MenuLink href="/#tints" label="odstíny" />
            <MenuLink href="/#contactForm" label="kontakt" />
            <MenuLink href="/shop-page" label="obchod" />
          </nav>
        </div>
      )}
    </div>
  );
}
