import React from "react";
import { Link } from "gatsby";

export default function MenuLink({ href, label }) {
  return (
    <Link to={href} className="uppercase" activeClassName="underline">
      {label}
    </Link>
  );
}
