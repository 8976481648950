import React, { createContext, useContext, useState } from "react";
import { useWarehouse } from "./warehouseContext";

const CartContext = createContext();

function CartProvider({ children }) {
  const {warehouse} = useWarehouse()
  const [cart, setCart] = useState([]);

  function addItem(itemID) {
    var newItem = true;
    const tempCart = cart;
    for (var i = 0; i < tempCart.length; i++) {
      if (tempCart[i].id === itemID) {
        tempCart[i].count = tempCart[i].count + 1;
        newItem = false;
      }
    }
    if (newItem === true) {
      setCart([...cart, { id: itemID, count: 1 }]);
    } else {
      setCart([...tempCart]);
    }
  }

  function removeItem(itemID) {
    const tempCart = cart;
    for (var i = 0; i < tempCart.length; i++) {
      if (tempCart[i].id === itemID) {
        if (tempCart[i].count > 1) {
          tempCart[i].count = tempCart[i].count - 1;
        } else {
          tempCart.splice(i, 1);
          i--;
        }
      }
    }
    setCart([...tempCart]);
  }

  function deleteItem(itemID) {
    const tempCart = cart;
    for (var i = 0; i < tempCart.length; i++) {
      if (tempCart[i].id === itemID) {
        tempCart.splice(i,1)
      }
    }
    setCart([...tempCart]);
  }

  function getCountOfItemsInCart() {
    let itemsInCart = 0
    cart.forEach(element => {
        itemsInCart = itemsInCart + element.count
    });
    return itemsInCart
  }

  function getItemCountInCart(itemID) {
    const item = cart.find((item) => item.id === itemID)
    return item.count
  }

  function isItemInCart(itemID) {
    var itemIsInCart = false
    if (cart.find((item) => item.id === itemID) !== undefined) { itemIsInCart = true; }
    return itemIsInCart
  }

  function getValueOfCart() {
    let valueOfCart = 0
     cart.forEach((item) => {
       const itemInCart = warehouse.find((element) => item.id === element.id);
       valueOfCart = valueOfCart + item.count * itemInCart.volume.frontmatter.price;
     });
    
    return valueOfCart
  }

  function clearCart() {
    setCart([])
  }

  const values = {
    cart,
    addItem,
    removeItem,
    deleteItem,
    clearCart,
    getCountOfItemsInCart,
    getItemCountInCart,
    getValueOfCart,
    isItemInCart,
  };

  return <CartContext.Provider value={values}>{children}</CartContext.Provider>;
}

function useCart() {
  const value = useContext(CartContext);
  return value;
}

export { CartContext, CartProvider, useCart };
