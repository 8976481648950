import React, { useEffect, useState } from "react";

export default function Section({ id, header, subheader, children, theme }) {
  const [textColor, setTextColor] = useState("text-black");
  const [bgColor, setBgColor] = useState("bg-white");

  useEffect(() => {
    switch (theme) {
      case "dark":
        setTextColor("text-gray-200");
        setBgColor("bg-gray-700");
        break;
      case "light":
        setTextColor("text-gray-700");
        setBgColor("bg-gray-100");
        break;
      default:
        break;
    }
  }, [theme]);

  const containerStyle = `md:container py-16 px-2 md:px-8 mx-auto ${bgColor}`;
  const sectionStyle = `relative ${textColor}`;

  return (
    <section className={sectionStyle}>
      <span id={id} className="absolute -top-16 md:-top-24" />

      <div className={containerStyle}>
        {header && subheader && (
          <div className="mb-10 text-center">
            <h1 className="mb-2 text-4xl font-light uppercase">{header}</h1>
            <article className="text-lg font-extralight">{subheader}</article>
          </div>
        )}
        {children}
      </div>
    </section>
  );
}
